<script>
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import { required} from "vuelidate/lib/validators";
import {
  projectMethods,
} from "@/state/helpers";

export default {
  components: {
    EmptyList    
  },
  data() {
    return {
      redirects:[],
      empty_config:{
          "title" : this.$t('search_settings.redirects_empty_title'),
          "subtitle" : this.$t('search_settings.redirects_empty_text'),
          "buttonText" : this.$t('search_settings.redirects_new'),
          icon: "fa-cubes"
      },
      empty_list_config:{
					"title" : this.$t("search_settings.redirects_empty_search_title"),
					"linkButtonText" : this.$t("common.reset_search"),
					icon: "fa-cubes"
			},
      isLoading:false,
      currentPage:1,
			perPage:12,
			rows:0,
      query:'',
      showRedirectModal: false,
      redirect: {
        is_active: 1
      },
      submitted: false,
      redirect_active: true,
      confirmDelete: false,
      redirectId: null
    }
  },
  validations: {
		redirect: {
			query_text: {
				required,
			},
      redirect_url: {
				required,
			},
    }
  },
  methods: {
    ...projectMethods,

    loadRedirects(){
			this.isLoading = true;
			let loader = this.$loading.show();
			const params={
				q: `project=${localStorage.getItem('current_project')}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
			}
			this.getSearchRedirects(params).then((data)=>{
        if(data){
					this.submitted= false;
					this.redirects= data.data;
					this.rows= data.total
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t('search_settings.get_redirects_error'),title:  this.$t('search_settings.redirects_title') });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
		},

    searchRedirects(query){
      let loader = this.$loading.show();
			this.isLoading = true;
			this.redirects=[];
			this.empty_list_config.subtitle =  this.$t("search_settings.redirects_empty_search_text").replaceAll('#', this.query);
			if(query){
				this.currentPage = 1;
        const params={
          q: `project=${localStorage.getItem('current_project')}&query_text=${query}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
        }
        this.getSearchRedirects(params).then((data)=>{
          if(data){
            this.redirects= data.data;
            this.rows= data.total;
          }
        }).catch(()=>{
            this.$notify({ type: 'error', text: this.$t("search_settings.get_redirects_error"),title:  this.$t("search_settings.redirects_title") });
        }).finally(()=>{
          this.isLoading = false;
          loader.hide();
        });
			}else{
				this.currentPage = 1;
				loader.hide();
				this.loadRedirects();
			}
    },
    onNewRedirectClicked(){
      this.redirect = {
        is_active: 1
      };
      this.submitted = false;
      this.showRedirectModal = true;
    },
    onEditRedirectClicked(data){
      this.redirect = Object.assign({}, data);
      this.redirect_active = this.redirect.is_active;
      this.showRedirectModal = true;
    },
    onDeleteRedirectClicked(redirectId){
      this.redirectId = redirectId;
      this.confirmDelete = true;
    },
    onredirectListPageClicked(){
      this.loadRedirects();
    },
    onCreateOrEditRedirectClicked() {
      this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			let loader = this.$loading.show();
      this.redirect.project = localStorage.getItem('current_project');
			if(this.redirect._id) {
        this.updateRedirect(this.redirect).then(()=>{
          this.$notify({ type: 'success', text: this.$t('search_settings.update_redirect_success'),title: this.$t('search_settings.redirects_title') });
          this.showRedirectModal= false;
          this.currentPage=1;
          this.query=''
          this.loadRedirects();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('search_settings.update_redirect_error'),title:  this.$t('search_settings.redirects_title') });		
        }).finally(()=>{
          loader.hide();
          this.isLoading = false;
          this.submitted = false;
        })
			}else {
				this.createRedirect(this.redirect).then(()=>{ 
          this.$notify({ type: 'success', text: this.$t('search_settings.create_redirect_success'),title: this.$t('search_settings.redirects_title') });
          this.showRedirectModal= false;
          this.currentPage=1;
          this.query=''
          this.loadRedirects();

        }).catch(()=> {
          this.$notify({ type: 'error', text: this.$t('search_settings.create_redirect_error'),title:  this.$t('search_settings.redirects_title') });		
        }).finally(()=>{
          loader.hide();
          this.isLoading = false;
          this.submitted = false;
        })
			}
		},
    onRedirectActiveChange(){
      this.redirect.is_active = this.redirect_active;
    },
    onResetSearchClicked(){
      this.query = '';
      this.currentPage = 1;
      this.loadRedirects();
    },
    onConfirmRemoveRedirectClicked(){
      let loader = this.$loading.show();

      this.deleteRedirect(this.redirectId).then(()=>{
        this.currentPage=1;
        this.loadRedirects();
        this.$notify({ type: 'success', text: this.$t("search_settings.remove_redirects_success"),title:  this.$t("search_settings.redirects_title") });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("search_settings.remove_redirects_error"),title:  this.$t("search_settings.redirects_title") });
      }).finally(()=>{
        loader.hide();
      })
    }
  },
  mounted() {
    this.loadRedirects();
  },
  created(){
    this.debounceRedirects = _debounce(this.searchRedirects.bind(this), 1000);
  },
  props: {
    
  },
}

</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card mb-0">
				<div class="card-body">
          <div class="row mb-0">
              <div class="col-sm-4">
                  <div class="search-box me-2 mb-0 d-inline-block">
                      <div class="position-relative">
                          <input
                              type="text"
                              class="form-control"
                              :placeholder="$t('common.search')"
                              @input="debounceRedirects($event.target.value)"
                              v-model="query"
                          />
                          <i class="bx bx-search-alt search-icon"></i>
                      </div>
                  </div>
              </div>
              <div class="col-sm-8">
                  <div class="text-sm-end">
                      <button
                          type="button"
                          class="btn btn-primary mb-0 me-0"
                          @click="onNewRedirectClicked">
                      <i class="mdi mdi-plus me-1"></i> {{ $t('search_settings.redirects_new')}}
                  </button>
                </div>
              </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && redirects.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if=" !isLoading && redirects.length == 0 && query ==''" @onButtonClicked="onNewRedirectClicked" class="mt-3 mb-3"/>
          <div class="row mt-3">
            <div class="table-responsive" v-if="redirects.length>0">
                <table  id="my-table" class="table align-middle table-nowrap table-hover" style="background-color: white;">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">{{$t('search_settings.redirects_query_text')}}</th>
                                <th scope="col">{{$t('search_settings.redirects_url')}}</th>
                                <th scope="col">{{$t('search_settings.redirects_status')}}</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="data in redirects" :key="data._id">
                                <td>{{data.query_text}}</td>
                                <td>{{data.redirect_url}}</td>
                                <td>
                                    <span class="badge font-size-10"
                                        :class="{
                                        'bg-success': data.is_active === true || data.is_active == 1,
                                        'bg-warning': data.is_active  === false || data.is_active == 0,
                                    }">{{ data.is_active || data.is_active == 1 ? $t('search_settings.redirects_status_active') : $t('search_settings.redirects_status_inactive') }}</span>
                                </td>
                                <td class="text-end">
                                  <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditRedirectClicked(data)"></i>
                                  <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onDeleteRedirectClicked(data._id)"></i>
                                </td>
                            </tr>
                          </tbody>
                </table>
            </div>
            <div class="mb-3" v-if="rows > perPage">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                        @input="onredirectListPageClicked"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage">
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveRedirectClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{$t('search_settings.remove_redirect')}}</p>
        </b-modal>
          <b-modal
            v-model="showRedirectModal"
            :title="redirect?._id ? $t('search_settings.redirects_edit') : $t('search_settings.redirects_new')"
            title-class="text-black font-18"
            body-class="p-3"
            @ok.prevent="onCreateOrEditRedirectClicked" :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                      <label for="name">{{$t('search_settings.redirects_query_text')}}*</label>
                      <input
                      id="name"
                      v-model="redirect.query_text"
                      type="text"
                      class="form-control"
                      :placeholder="$t('search_settings.redirects_query_text_placeholder')"
                      :class="{
                          'is-invalid':
                          submitted && $v.redirect.query_text.$error,
                      }"
                      />
                      <div
                      v-if="submitted && !$v.redirect.query_text.required"
                      class="invalid-feedback">
                        {{$t('search_settings.redirects_query_text_required')}}
                      </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                      <label for="name">{{$t('search_settings.redirects_url')}}*</label>
                      <input
                      id="name"
                      v-model="redirect.redirect_url"
                      type="url"
                      class="form-control"
                      :placeholder="$t('search_settings.redirects_url_placeholder')"
                      :class="{
                          'is-invalid':
                          submitted && $v.redirect.redirect_url.$error,
                      }"
                      />
                      <div
                      v-if="submitted && !$v.redirect.redirect_url.required"
                      class="invalid-feedback">
                        {{ $t('search_settings.redirects_url_required') }}
                      </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <b-form-checkbox
                        v-model="redirect_active"
                        class="mb-3"
                        checked
                        @change="onRedirectActiveChange"
                        plain>{{$t('search_settings.redirects_status_active')}}</b-form-checkbox>
                    </div>
                </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>