<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Redirects  from "@/components/search/redirects"
import Test  from "@/components/search/test"

import {
	
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Search Settings",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Redirects,
    Test
    
  },
  data() {
    return {
      title: "Settings",
      items: [],
      showModalHost: false,
      showModalProducts:false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      project:null,
      submitted:false,
      indexTab: 0,
      isLocal: window.config.local ? true: false
    }
  },
  mounted() {
    
  
  },
  created() {
    
  },
  methods: {
  
},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('search_settings.title')" :items="items" />
    <div>
      <b-tabs v-model="indexTab" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
        <b-tab active v-if="isLocal">
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">General</span>
          </template>
          <div class="card">
            - goal
            - typo tolerance 
            - stop words
            - retrieve properties
            - distinct properties 
          </div>
        </b-tab>
        <b-tab v-if="isLocal">
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">Searchable properties</span>
          </template>
          <div class="card">
            
          </div>
        </b-tab>
        <b-tab v-if="isLocal">
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">Ranking and sorting</span>
          </template>
          <div class="card">
            
          </div>
        </b-tab>
        <b-tab v-if="isLocal">
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">Sinónimos</span>
          </template>
          <div class="card">
            
          </div>
        </b-tab>
        <b-tab v-if="isLocal">
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">Reglas</span>
          </template>
          <div class="card">
            
          </div>
        </b-tab>
        <b-tab class="px-0 ">
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">{{$t('search_settings.redirects_title')}}</span>
          </template>
          <Redirects/>
        </b-tab>
        <b-tab class="px-0 ">
          <template v-slot:title>
            <span class="d-none d-sm-inline-block">{{$t('menu.search_test')}}</span>
          </template>
          <Test/>
        </b-tab>
      </b-tabs>
    </div>
  </Layout>
</template>

<style>
.tab-content{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>

