<!-- eslint-disable no-undef -->
<script>

import {
  projectMethods
} from "@/state/helpers";

export default {
  components: {
    
  },
  data() {
    return {
      
    }
  },
  methods: {
    ...projectMethods,

    onButtonClicked(){
      const loader = this.$loading.show();

      const params = {
        q: `project=${localStorage.getItem('current_project')}`
      }
      this.getDiscoverToken(params).then((res)=>{
        if(res.token){
          const env = window.config.env == 'dev' ? 'D' : 'BR' 
          const q = `_gsPlaygroundEnv=${env}&_gsPlaygroundToken=${res.token}`
          window.open(`https://search-playground.gopersonal.ai?${q}`,'blank');
        }
      }).finally(()=>{
        loader.hide();
      })
    },
    onCopyClicked(){
      const loader = this.$loading.show();
      const params = {
        q: `project=${localStorage.getItem('current_project')}`
      }
      this.getDiscoverToken(params).then((res)=>{
        if(res.token){
          const env = window.config.env == 'dev' ? 'D' : 'BR' 
          const url = `https://search-playground.gopersonal.ai?_gsPlaygroundEnv=${env}&_gsPlaygroundToken=${res.token}`
          navigator.clipboard.writeText(url);
          this.$notify({ type: 'success', text: this.$t("search_test.copy_success"),title: 'Go Search' });
        }
      }).finally(()=>{
        loader.hide();
      })
    }
  },

  mounted() {

    
  },
  props: {},
}

</script>

<template>
  <div class="row d-flex justify-content-center">
    <div class="col-xl-5 col-sm-8">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <h4 class="mt-4 fw-semibold">Go Search</h4>
                  <p class="text-muted mt-3">
                    {{ $t('search_test.description') }}
                  </p>
                  <div class="mt-4">
                    <!-- Button trigger modal -->
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="onButtonClicked">
                      {{ $t('search_test.test_now') }}
                    </button>
                    
                  </div>
                  <button class="btn btn-md btn-link me-2" type="button" @click="onCopyClicked">{{ $t('search_test.copy') }}</button>
                </div>
                <div class="row justify-content-center mt-5 mb-2">
                  <div class="col-sm-6 col-8">
                    <div>
                      <img
                        src="@/assets/images/search_test.jpeg"
                        alt
                        class="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>